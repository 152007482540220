"use strict";

require("jquery");
require("jquery-ui-dist/jquery-ui.js");
window.$ = $;
window.jQuery = $;
const rubyApp = require('./index');
const RubyComponent = require('@rubyapps/ruby-component');
const RubyComponentRubyApp = require('@rubyapps/ruby-component-ruby-app-simple');
rubyApp.init({
  rootComponent: RubyComponent.createElement(RubyComponentRubyApp)
}).then(function (rubyApp) {});